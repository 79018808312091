@font-face {
	font-family: 'Museo Sans Cyrl 500';
	src: url('../fonts/MuseoSansCyrl-500.eot');
	src: local('MuseoSansCyrl-500'),
		url('../fonts/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-500.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-500.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl 300';
	src: url('../fonts/MuseoSansCyrl-300Italic.eot');
	src: local('MuseoSansCyrl-300Italic'),
		url('../fonts/MuseoSansCyrl-300Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-300Italic.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-300Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Museo Sans Cyrl 500';
	src: url('../fonts/MuseoSansCyrl-500Italic.eot');
	src: local('MuseoSansCyrl-500Italic'),
		url('../fonts/MuseoSansCyrl-500Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-500Italic.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-500Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Museo Sans Cyrl 900';
	src: url('../fonts/MuseoSansCyrl-900.eot');
	src: local('MuseoSansCyrl-900'),
		url('../fonts/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-900.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-900.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl 700';
	src: url('../fonts/MuseoSansCyrl-700.eot');
	src: local('MuseoSansCyrl-700'),
		url('../fonts/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-700.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-700.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl 700';
	src: url('../fonts/MuseoSansCyrl-700Italic.eot');
	src: local('MuseoSansCyrl-700Italic'),
		url('../fonts/MuseoSansCyrl-700Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-700Italic.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-700Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Museo Sans Cyrl 300';
	src: url('../fonts/MuseoSansCyrl-300.eot');
	src: local('MuseoSansCyrl-300'),
		url('../fonts/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-300.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-300.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl 100';
	src: url('../fonts/MuseoSansCyrl-100.eot');
	src: local('MuseoSansCyrl-100'),
		url('../fonts/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-100.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-100.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Museo Sans Cyrl 100';
	src: url('../fonts/MuseoSansCyrl-100Italic.eot');
	src: local('MuseoSansCyrl-100Italic'),
		url('../fonts/MuseoSansCyrl-100Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-100Italic.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-100Italic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Museo Sans Cyrl 900';
	src: url('../fonts/MuseoSansCyrl-900Italic.eot');
	src: local('Museo Sans Cyrl 900 Italic'), local('MuseoSansCyrl-900Italic'),
		url('../fonts/MuseoSansCyrl-900Italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MuseoSansCyrl-900Italic.woff') format('woff'),
		url('../fonts/MuseoSansCyrl-900Italic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
