@media (max-width: 1199.98px) {}

@media (max-width: 991.98px) {
    .slogan__first {
        font-size: 1.9rem;
        line-height: 4.2rem;
        letter-spacing: 0.5rem;
        padding: 0 5%;
    }

    .slogan__second {
        font-size: 1.5rem;
        line-height: 2rem;
        letter-spacing: 0.3rem;
        padding: 0 5%;
    }

    .product-card-item__list {
        width: 96%;
    }
    .list-item__icon {
        margin-top: 3px;
    }
    .list-item__text {

        /*font-size: 1.4rem;*/
        /*line-height: 1.5rem;*/
        /*margin-top: 11px;*/

    }
}
@media (max-width: 767.98px) {
    .slogan__first {
        font-size: 2.2rem;
        line-height: 3rem;
        letter-spacing: 0.4rem;
        padding-left: 5%;
    }
    .slogan__second {
        font-size: 1.5rem;
        line-height: 2rem;;
        letter-spacing: 0.28rem;
        padding: 0 10%;
    }
    #our-contacts {
        height: auto;
        margin-bottom: 0;
    }
    .product-card-item__list {
        padding: 0;
    }
    list-item__icon::before {
        font-size: 1.4rem;
    }
    .first-page, .first-page-overlay {
        height: auto;
    }
    .products-item-overlay {
        display: none;
    }
}

@media (max-width: 575.98px) {
    .slogan__first {
        font-size: 2.3rem;
        line-height: 3.2rem;
        letter-spacing: 0.2rem;
    }

    .slogan__second {
        font-size: 1.6rem;
        line-height: 2.3rem;
        letter-spacing: 0.08rem;
    }

    .products-item-overlay {
        font-size: 0.8rem;
        line-height: 20px;
    }
    .nav-bar {
        height: 90px;
    }
    h2 {
        font-size: 25px;
    }
    h3 {
        font-size: 20px;
        padding-top: 10px;
    }
    #footer {
        height: auto;
    }
}








