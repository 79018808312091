html {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 15px;
}

.animation-on {
    -moz-transition: all 0.4s ease;
    -webkit-transition-property: all;
    -webkit-transition-duration: 0.4s;
    -webkit-transition-timing-function: ease;
}

h1,
h2,
h3,
h5,
div,
p,
ul,
li {
    display: block;
    padding: 0;
    margin: 0;
}

a,
a:active,
a:hover,
a:focus,
button,
button:active,
button:hover,
button:focus,
input,
input:active,
input:focus,
input:hover {
    display: inline-block;
    text-decoration: none;
    outline: none;
}

h1 {
    display: none;
}
h2 {
    text-align: center;
    font-weight: 700;
    font-size: 42px;
    text-transform: uppercase;
    letter-spacing: 4.2px;
}
h3 {
    /*color: white;*/
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2rem;
    padding-top: 35px;
    padding-bottom: 15px;
    /*text-shadow: 2px 2px 2.2px black, 0 0px 1.7em white;*/
}
h4 {
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 23px;
    padding-top: 35px;
}
h5 {
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    padding: 35px 0 15px 0 ;
    width: 100%;
}
.zidx {
    position: relative;
    z-index: 1;
}
.hide-element {
    height: 0;
}
.menu-circle {
    width: 100px;
    height: 100px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    border: 2px solid white;
}
/*========== ГЛАВНАЯ СТРИНЦА ==============*/
.arrow-home {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 2%;
    bottom: 11%;
    border-radius: 50%;
    border: 1px solid #23aae2;
    text-align: center;
    line-height: 45px;
    font-size: 25px;
    color: #23aae2;
    z-index: 100;
}
.arrow-home:hover {
    background-color: white;
}
.arrow-top {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 1%;
    bottom: 11%;
    border-radius: 50%;
    border: 1px solid #23aae2;
    text-align: center;
    line-height: 45px;
    font-size: 25px;
    color: #23aae2;
    z-index: 1;
}
.arrow-top:hover {
    background-color: white;
}
.first-page {
    min-height: 100vh;
    width: 100%;
    background: url('../img/bg_property.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #b4c1c2;
    position: relative;
    background-position: center center;
    padding-bottom: 52px;
    z-index: 2;
}
.first-page-card {
    min-height: 100vh;
    width: 100%;
    /*background: url('../img/auto_bg.jpg');*/
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #b4c1c2;
    position: relative;
    background-position: right center;
}
.auto_bg {
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%) , url("../img/auto_bg.jpg");
    background-image: -moz-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/auto_bg.jpg");
    background-image: -webkit-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/auto_bg.jpg");
    background-image: -o-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/auto_bg.jpg");
    background-image: -ms-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/auto_bg.jpg");
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/auto_bg.jpg");
}
.bus_bg {
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%) , url("../img/bus_bg.jpg");
    background-image: -moz-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/bus_bg.jpg");
    background-image: -webkit-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/bus_bg.jpg");
    background-image: -o-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/bus_bg.jpg");
    background-image: -ms-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/bus_bg.jpg");
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/bus_bg.jpg");
}
/*.commercial_bg {*/
/*    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%) , url("../img/commercial_bg.jpg");*/
/*    background-image: -moz-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/commercial_bg.jpg");*/
/*    background-image: -webkit-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/commercial_bg.jpg");*/
/*    background-image: -o-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/commercial_bg.jpg");*/
/*    background-image: -ms-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/commercial_bg.jpg");*/
/*    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/commercial_bg.jpg");*/
/*}*/
.commercial_bg {
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%) , url("../img/truck_bg.jpg");
    background-image: -moz-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/truck_bg.jpg");
    background-image: -webkit-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/truck_bg.jpg");
    background-image: -o-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/truck_bg.jpg");
    background-image: -ms-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/truck_bg.jpg");
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/truck_bg.jpg");
}
.special_bg {
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%) , url("../img/special_bg.jpg");
    background-image: -moz-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/special_bg.jpg");
    background-image: -webkit-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/special_bg.jpg");
    background-image: -o-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/special_bg.jpg");
    background-image: -ms-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/special_bg.jpg");
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/special_bg.jpg");
}
.equipment_bg {
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%) , url("../img/equipment_bg.jpg");
    background-image: -moz-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/equipment_bg.jpg");
    background-image: -webkit-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/equipment_bg.jpg");
    background-image: -o-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/equipment_bg.jpg");
    background-image: -ms-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/equipment_bg.jpg");
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/equipment_bg.jpg");
}
.property_bg {
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%) , url("../img/property_bg.jpg");
    background-image: -moz-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/property_bg.jpg");
    background-image: -webkit-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/property_bg.jpg");
    background-image: -o-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/property_bg.jpg");
    background-image: -ms-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/property_bg.jpg");
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/property_bg.jpg");
}

.first-page-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    /*background: rgba(0,196,245,0.3);*/
    background: rgba(35, 170, 226,0.1);
    /*background: -moz-linear-gradient(-45deg, rgba(237,237,237,0.1) 0%, rgba(246,246,246,0.1) 47%, rgba(255,255,255,0.1) 100%);*/
    /*background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(237,237,237,0.1)), color-stop(47%, rgba(246,246,246,0.1)), color-stop(100%, rgba(255,255,255,0.1)));*/
    /*background: -webkit-linear-gradient(-45deg, rgba(237,237,237,0.1) 0%, rgba(246,246,246,0.1) 47%, rgba(255,255,255,0.1) 100%);*/
    /*background: -o-linear-gradient(-45deg, rgba(237,237,237,0.1) 0%, rgba(246,246,246,0.1) 47%, rgba(255,255,255,0.1) 100%);*/
    /*background: -ms-linear-gradient(-45deg, rgba(35, 170, 226, 0.1) 0%, rgba(35, 170, 226, 0.1) 47%, rgba(35, 170, 226, 0.1) 100%);*/
    /*background: linear-gradient(135deg, rgba(35, 170, 226,0.3) 0%, rgba(35, 170, 226,0.3) 47%, rgba(35, 170, 226,0.3) 100%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#ffffff', GradientType=1 );*/
}
.nav-bar {
    height: 77px;
    background-color: rgba(0, 0, 0, 0.45);
}
.card-header-bg {
    background-color: #266188;
}
.logo-link {
}
.logo {
    font-family: 'Open Sans', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 400;
    font-size: 25px;
    color: white;
    white-space: nowrap;
    /*text-shadow: 2px 2px 2.2px black, 0 0px 1.7em white;*/
}
.shadow-text {
    /*text-shadow: 2px 2px 2.2px black, 0 0px 1.7em white;*/
}
    /*.img-logo {*/
/*    width: 100%;*/
/*}*/
.top-menu {
    height: 77px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.top-menu__item {
    color: #ffffff;
    font-size: 14px;
    white-space: nowrap;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1.1px;
    padding: 11px 15px 9px 14px;

}

.top-menu__item:hover {
    color: #ffffff;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.14);
    text-shadow: 2px 2px 2.2px black, 0 0px 1.7em white;
    cursor:pointer;
}
.top-menu__item:active {
    box-shadow: 0px 0px 15px rgba(255,255,255,0.5) inset;
}
.burger-menu {
    font-size: 44px;
    color: #fff;
}
.phone {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.circle {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
}
.circle_blue {
    border: 1px dashed #23aae2;
}
.circle_white {
    border: 1px dashed #ffffff;
}
.circle_black {
    border: 1px dashed #000000;
}
.tel-icon:before {
    content: "\f095";
    font-family: FontAwesome, serif;
}
.tel-icon_blue {
    color: #23aae2;
}
.tel-icon_white {
    color: #ffffff;
}
.tel-icon_black {
    color: #000000;
}

.tel-num {
    font-size: 16px;
    letter-spacing: 0.32px;
    margin-left: 40px;
    white-space: nowrap;
}
.tel-num_white {
    color: #ffffff;
}
.tel-num_black {
    color: #000000;
}

.tel-link {
    text-decoration: none;
}
.tel-link_black, .tel-link_black:hover, .tel-link_black:active {
    color: black;
}
.tel-link_white, .tel-link_white:hover, .tel-link_white:active {
    color: white;
}
.tel-text {
    /*font-size: 16px;*/
    /*letter-spacing: 0.32px;*/
    /*white-space: nowrap;*/
}


.email {
    display: flex;
    justify-content: flex-end;
    align-items: center
}
.email-icon:before {
    content: "\f003";
    font-family: FontAwesome, serif;
}
.email-icon_blue {
    color: #23aae2;
}
.email-icon_white {
    color: #ffffff;
}
.email-icon_black {
    color: #000000;
}
/*.email-button {*/
/*    height: 26px;*/
/*    width: 26px;*/
/*    border-radius: 50%;*/
/*    text-align: center;*/
/*    line-height: 24px;*/
/*    border: 1px dashed #23aae2;*/
/*}*/

/*.email-button:before {*/
/*    content: "\f003";*/
/*    font-family: FontAwesome, serif;*/
/*}*/

.email-info {
    font-size: 16px;
    letter-spacing: 0.32px;
    margin-left: 4px;
}
.email-info_white {
    color: white;
}
.email-info_blue {
    color: #23aae2;
}
.email-info_black {
    color: black;
}

.email-link {
    text-decoration: none;
}
.email-link_white {
    color: #ffffff;
}
.email-link_white, .email-link_white:hover, .email-link_white:active {
    color: #ffffff;
}
.email-link_black {
    color: #000000;
}
.email-link_black, .email-link_black:hover, .email-link_black:active {
    color: #000000;
}
.tel-label {}
.email-label {}
.email-text {}
.slogan {
    /*text-transform: uppercase;*/
    color: #ffffff;
}
.slogan-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    font-size: 2.6rem;
    letter-spacing: 0.5rem;
    z-index: 1;
    font-weight: 300;
}

.slogan__first {
    padding-left: 16px;
}
.slogan__first span {
    /*text-transform: uppercase;*/
}
.slogan__second {
    padding-left: 16px;
}

/*.arrow {*/
/*    color: #fff;*/
/*    height: 46px;*/
/*    width: 46px;*/
/*    border: 1px dashed #fff;*/
/*    border-radius: 50%;*/
/*    text-align: center;*/
/*    line-height: 45px;*/
/*    font-size: 15px;*/
/*    font-weight: 400;*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    left: 50%;*/
/*    margin-left: -23px;*/
/*    margin-bottom: 5%;*/
/*}*/

/*========== ПРЕДМЕТЫ ЛИЗИНГА ==============*/

.products {
    width: 100%;
    background: url('../img/products_grey_bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ffffff;
    background-position: right center;
}
.products-list {
    margin-top: 50px;
}
.products-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /*background-color: rgba(111,111,111,0.22);*/
    border-radius: 10px;
    /*color: #23aae2;*/
    /*position: relative;*/
}
.products-item:hover .products-item-overlay {
    opacity: 1;
    /*box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.57);*/
}
.products-item:active .products-item-overlay {
    box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.5) inset;
}
.products-item:hover .products-item__logo {
    background-color: rgba(114, 166, 226, 0.93);
    border-radius: 50%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.57);
}
.products-item:active .products-item__logo {
    box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.5) inset;
}
.products-item_selected {
    /*color: #ffffff;*/
    /*background-color: rgba(0,0,0,0.5);*/
    /*border-radius: 10px;*/
    /*box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.57);*/
}
.products-item-overlay {
    /*position: absolute;*/
    /*margin-top: 5px;*/
    /*opacity: 0;*/
    /*text-align: center;*/
    /*bottom: 0;*/
    /*border-radius: 10px;*/
    /*font-size: 1rem;*/
    /*line-height: 30px;*/
    /*color: white;*/
    /*background-color: rgba(35, 170, 226, 1);*/
    /*padding: 0 10px;*/
    /*border: 2px solid white;*/
    margin-top: 5px;
    opacity: 0;
    text-align: center;
    bottom: 0;
    border-radius: 10px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 30px;
    color: white;
    /*background-color: rgba(114, 166, 226, 0.93);*/
    padding: 0 10px;
    /*border: 2px solid white;*/
    letter-spacing: 0.06rem;
}
/*.products-item-overlay:hover {*/
    /*opacity: 1;*/
/*}*/
/*.products-item:hover {*/
    /*color: #ffffff;*/
    /*background-color: rgba(0,0,0,0.5);*/
    /*border-radius: 50%;*/

    /*box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.57);*/
/*}*/
/*.products-item:active {*/
    /*box-shadow: 0px 0px 25px rgba(255, 255, 255, 0.5) inset;*/
    /*opacity: 1;*/
/*}*/
.products-item__logo {
height: 100px;
width: 100px;
align-items: center;
-moz-border-radius: 50px;
-webkit-border-radius: 50px;
border-radius: 50px;
border: 2px solid white;
}
.products-item__text {
color: inherit;
    font-weight: 800;
font-size: 1rem;
text-align: center;
}
.auto {
background: url("../img/auto.png") no-repeat center center;
background-size: 65px;
}
.bus {
background: url("../img/bus.png") no-repeat center center;
background-size: 80px;
}
.commercial {
background: url("../img/commercial.png") no-repeat center center;
}
.truck {
background: url("../img/truck.png") no-repeat center center;
}
.special {
background: url("../img/special.png") no-repeat center center;
}
.equipment {
background: url("../img/equipment.png") no-repeat center center;
}
.property {
background: url("../img/property.png") no-repeat center center;
}
.product-cards {}

.product-card-item {}

.product-card-item__list {
width: 90%;
margin: 0 auto;
padding: 35px 0;
}
.product-card-item__list-item {
display: flex;
flex-direction: row;
justify-content: flex-start;
    margin-top: 15px;
}
.list-item__icon {}
.list-item__icon:before {
content: "\f078";
font-family: FontAwesome, serif;
}
.list-item__text {
    margin-left: 10px;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.4rem;
}


/*.product-auto {*/
/*width: 100%;*/
/*background-image: linear-gradient(135deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) 100%) , url("../img/auto_bg.jpg");*/
/*background-repeat: no-repeat;*/
/*background-size: cover;*/
/*background-position: right center;*/
/*background-image: -moz-linear-gradient(-45deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) 100%), url("../img/auto_bg.jpg");*/
/*!*background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,255,255,0.6)), color-stop(100%, rgba(255,255,255,0.6)));*!*/
/*    background-image: -webkit-linear-gradient(-45deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) 100%), url("../img/auto_bg.jpg");*/
/*    background-image: -o-linear-gradient(-45deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) 100%), url("../img/auto_bg.jpg");*/
/*    background-image: -ms-linear-gradient(-45deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) 100%), url("../img/auto_bg.jpg");*/
/*    background-image: linear-gradient(135deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.6) 100%), url("../img/auto_bg.jpg");*/
/*    !*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );*!*/

/*}*/
/*========== КОНТАКТНАЯ ИНФОРМАЦИЯ ==============*/
#our-contacts {
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%) , url("../img/products_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right center;
    background-image: -moz-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/products_bg.jpg");
    /*background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,255,255,0.8)), color-stop(100%, rgba(255,255,255,0.8)));*/
    background-image: -webkit-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/products_bg.jpg");
    background-image: -o-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/products_bg.jpg");
    background-image: -ms-linear-gradient(-45deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/products_bg.jpg");
    background-image: linear-gradient(135deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.8) 100%), url("../img/products_bg.jpg");
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );*/
    /*margin-bottom: -77px;*/
    z-index: 2;
}
.card-text {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.32px;
}

.map   {
    height: 100%;
    padding-top: 36px;
}
/*========== ФУТЕР ==============*/
#footer {
    /*background-color: #797f7f;*/
    background-color: #266188;
    height: 77px;
}
.footer {
    width: 100%;
    height: 77px;
}
.footer-logo {
    align-self: stretch;
}
.footer-copyright {
    white-space: nowrap;
    align-self: center;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}
.footer-politics {
    white-space: nowrap;
    align-self: center;
    font-size: 14px;
    font-weight: 300;
}
.footer-politics__link {
    text-decoration: none;
    border-bottom: 1px dotted;
    color: #fff;
}
.footer-politics__link:hover {
    color: #fff;
}
.contacts {
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.future-header-left {
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    border-bottom: 1px solid black;
}
.future-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.future-left {}
.future-left_item {
    margin-top: 8px;
    line-height: 16px;
    text-align: justify;
}
.future-left_item:before {
    content: "\f058";
    font-family: FontAwesome, serif;
    margin-right: 5px;
}
.future-header-right {
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    border-bottom: 1px solid black;
}
.future-right {}
.future-right_item {
    margin-top: 8px;
    line-height: 16px;
    text-align: justify;
}
.future-right_item:before {
    content: "\f058";
    font-family: FontAwesome, serif;
    margin-right: 5px;
}
.coffee {
    color: rgba(7,152,226,0.93);
    font-weight: bolder;
    font-style: italic;
    font-size: 17px;
    padding-bottom: 20px;
}
.coffee:before {
    color: rgba(7,152,226,0.93);
    font-style: normal;
    content: "\f0f4";
    font-family: FontAwesome, serif;
    margin-right: 5px;
}